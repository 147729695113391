import React, { useEffect, Fragment } from "react"
import { Img } from "react-image";
import ImageDarkTextRightWithButton from "./components/imageDarkTextRightWithButton"
import ImageDarkTextLeftWithButton from "./components/imageDarkTextLeftWithButton"
import CTABusiness from "../../general/ctaBusiness"
import FAQList from '../../general/faqsList';
import InnerPageHeader from "./components/innerPageHeader";
import { scrollToElement } from "../../../../utility/utils"
import DarkBelowFoldCards from "../../general/darkBelowFoldCards";
import MoreForYouComponent from "../../general/more-for-you";
import { moreForYou } from "./features";

import EntryIllustration from '../../../../../assets/img/payroll-hero-image.svg'
import EntryBlurIllustration from '../../../../../assets/img/business/blur/payroll-hero-blur.svg'
import FlatFeeIcon from "../../../../../assets/img/kuda-flat-fee.inline.svg"
import WarningIcon from "../../../../../assets/img/kuda-warning.inline.svg"
import MinuteIcon from '../../../../../assets/img/kuda-time.inline.svg'

import payIllustration from "../../../../../assets/img/business/payroll/payroll-illustration.svg"
import rushIllustration from "../../../../../assets/img/business/payroll/avoidRush-illustration.svg"
import errorFreeIllustration from "../../../../../assets/img/business/payroll/errorFree-illustration.svg"

const entryContent = {
  title: (<span>
    Paying your staff salaries is easier <span className="color-secondary">with Payroll</span> on Kuda Business.
  </span>),
  subtitle: "Schedule your monthly payroll, pay in advance if you need to, and see who you’re paying on the Kuda Business app.",
  name: "Get Kuda Business",
  url: "https://kudabusiness.onelink.me/epwz/jceed731/",
}

const businessTopFeatures = [
  {
    icon: <FlatFeeIcon />,
    text: "Automate your monthly payroll easily."
  },
  {
    icon: <MinuteIcon />,
    text: "Avoid the last-minute payday rush."
  },
  {
    icon:  <WarningIcon />,
    text: "Keep errors out of your salary payments."
  },
]

const payrollAutomation = {
  title: "Automate your payroll disbursement.",
  subtitle: "Payroll on Kuda Business will pay your staff salaries on time from your Kuda Business account.",
  url: "https://kudabusiness.onelink.me/epwz/jceed731/",
  name: "Download Kuda Business",
  illustration: (
    <Img src={payIllustration} className="kuda-business-image" />
  ),
}

const avoidPaydayRush = {
  title: "Avoid the last-minute payday rush.",
  subtitle: "Schedule salary payments up to weeks in advance to save time and keep your finances organised.",
  url: "https://kudabusiness.onelink.me/epwz/jceed731/",
  name: "Download Kuda Business",
  illustration: (
    <Img src={rushIllustration} className="kuda-business-image" />
  ),
}

const errorFreePayroll = {
  title: "Outsource your payroll for error-free payments.",
  subtitle: "With Payroll on Kuda Business, you won’t forget to pay anyone or pay the wrong amount.",
  url: "https://kudabusiness.onelink.me/epwz/jceed731/",
  name: "Get Kuda Business",
  illustration: (

    <Img src={errorFreeIllustration} className="kuda-business-image" />

  ),
}


const questions = [
  {
    heading: "What is payroll?",
    list: (
      <span className="flex flex-column  mt-2">
        <span className="faq-description f-16">Payroll is the process of paying your employees. It’s also a list of your employees, their salaries, taxes and other deductions as well as your business’ contributions and taxes.</span>
      </span>),
    index: 1
  },
  {
    heading: "Who can use Payroll on Kuda Business?",
    list: (
      <div className="flex flex-column  mt-2">
        <span className="faq-description f-16">
          Anyone with a full Kuda Business account can use Payroll on Kuda Business. To upgrade your Kuda Business account, just add your CAC registration documents.
        </span>
      </div>),
    index: 2
  },
  {
    heading: "Why should I use Payroll on Kuda Business?",
    list: (
      <div className="flex flex-column  mt-2">
        <span className="faq-description f-16">
          Use Payroll on Kuda Business to:
        </span>

        <span className="pt-3 career-message-margin-bottom ">
          <div className="flex flex-column  leading-md-6 f-md-16">
            <div className="mb-3">1. Make salary payments from your Kuda Business account easily.</div>
            <div className="mb-3">2. Speed up your payroll planning.</div>
            <div className="mb-3">3. Save yourself time that you can use to do other things.</div>
            <div className="mb-3">4. Keep your salary payments free of errors.</div>
            <div className="mb-3">5. Organise your finances better.</div>
            <div className="mb-3">You’ll also save money that you might have spent on a separate payroll app.</div>
          </div>
        </span>

      </div>),
    index: 3
  },
  {
    heading: "How do I use Payroll on Kuda Business?",
    list: (
      <div className="flex flex-column  mt-2">
        <span className="faq-description f-16">
          To use Payroll on Kuda Business, follow the steps below:
        </span>

        <span className="pt-3 career-message-margin-bottom ">
          <div className="flex flex-column  leading-md-6 f-md-16">
            <div className="mb-3">1. Sign in to Kuda Business <a href="https://kudabusiness.onelink.me/epwz/jceed731/" target="_blank" rel="noopener noreferrer"  className="cursor-pointer text-bold color-primary text-underline">here.</a></div>
            <div className="mb-3">2. Click Payroll on the left side of your screen.</div>
            <div className="mb-3">3. Click Set Up Payroll, then follow the prompts on your screen.</div>
          </div>
        </span>

      </div>),
    index: 4
  },
  {
    heading: "Is Payroll on Kuda Business secure?",
    list: (
      <span className="flex flex-column  mt-2">
        <span className="faq-description f-16">Yes, Payroll on Kuda Business is integrated with your Kuda Business account which makes it secure.</span>
      </span>),
    index: 5
  },
]


const KudaPayroll = () => {
  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
  }, [])

  return (

    <Fragment>

      <InnerPageHeader
        title={entryContent.title}
        subtitle={entryContent.subtitle}
        buttonName={entryContent.name}
        buttonUrl={entryContent.url}
        entryIllustration={EntryIllustration}
        fallback={<Img src={EntryBlurIllustration} className="hero-illustration" />}
      />
      <DarkBelowFoldCards topFeatures={businessTopFeatures} />
      <ImageDarkTextRightWithButton
        title={payrollAutomation.title}
        subtitle={payrollAutomation.subtitle}
        illustration={payrollAutomation.illustration}
        name={payrollAutomation.name}
        url={payrollAutomation.url}
        isExternal={true}
      />
      <ImageDarkTextLeftWithButton
        title={avoidPaydayRush.title}
        subtitle={avoidPaydayRush.subtitle}
        illustration={avoidPaydayRush.illustration}
        name={avoidPaydayRush.name}
        url={avoidPaydayRush.url}
        isExternal={true}
      />
      <ImageDarkTextRightWithButton
        title={errorFreePayroll.title}
        subtitle={errorFreePayroll.subtitle}
        illustration={errorFreePayroll.illustration}
        name={errorFreePayroll.name}
        url={errorFreePayroll.url}
        isExternal={true}
      />

      <FAQList title={"Payroll FAQs"} questions={questions} />
      <MoreForYouComponent moreForYou={moreForYou} />
      <CTABusiness />
    </Fragment>
  )
}

export default KudaPayroll

